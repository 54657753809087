import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RemoteCheckAccommodationStatusStoreEffects } from './effects';
import { remoteCheckAccommodationStatusReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'remoteCheckAccommodationStatus',
      remoteCheckAccommodationStatusReducer,
    ),
    EffectsModule.forFeature([RemoteCheckAccommodationStatusStoreEffects]),
  ],
})
export class RemoteCheckAccommodationStatusStoreModule {}
