import { createAction, props } from '@ngrx/store';

import {
  AirbnbContentAPIConfiguration,
  VrboContentAPIConfiguration,
} from '../../models';
import { Hooks } from '../../helpers';

export const loadRequest = createAction('[Channel Content API] Load Request');

export const loadSuccess = createAction('[Channel Content API] Load Success');

export const loadFailure = createAction(
  '[Channel Content API] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Channel Content API] Update Request',
  props<{
    propertyId: number;
    accommodationChannelId: number;
    channelId: number;
    more: AirbnbContentAPIConfiguration | VrboContentAPIConfiguration;
    hooks: Hooks;
  }>(),
);

export const updateSuccess = createAction(
  '[Channel Content API] Update Success',
);

export const updateFailure = createAction(
  '[Channel Content API] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Channel Content API] Reset State');
