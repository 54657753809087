import { createAction, props } from '@ngrx/store';

import { VirtualChannelMoveRequest } from '../../models/requests/virtual-channel-move.request';

export const moveRequest = createAction(
  '[Virtual Channel] Move Request',
  props<VirtualChannelMoveRequest>(),
);

export const moveSuccess = createAction('[Virtual Channel] Move Success');

export const moveFailure = createAction(
  '[Virtual Channel] Move Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Virtual Channel] Reset State');
