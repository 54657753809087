import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { VirtualChannelMoveRequest } from '../models/requests/virtual-channel-move.request';

@Injectable({
  providedIn: 'root',
})
export class VirtualChannelService {
  constructor(private http: HttpClient) {}

  move(payload: VirtualChannelMoveRequest) {
    return this.http.put('channelmanager/virtual_channel', payload);
  }
}
