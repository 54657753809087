import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { ChannelContentAPIService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class ChannelContentAPIStoreEffects {
  constructor(
    private dataService: ChannelContentAPIService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() =>
        this.dataService.load().pipe(
          map(() => {
            return fromActions.loadSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      flatMap(
        ({ propertyId, accommodationChannelId, more, channelId, hooks }) =>
          this.dataService
            .update(propertyId, accommodationChannelId, channelId, more)
            .pipe(
              effectHooks(hooks),
              map(() => {
                this.notifications.updateSuccess('airbnb_content_api');
                return fromActions.updateSuccess();
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.updateFailure(error));
              }),
            ),
      ),
    ),
  );
}
