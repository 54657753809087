import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { VirtualChannelService } from '../../services/virtual-channel.service';

import * as fromActions from './actions';

@Injectable()
export class VirtualChannelStoreEffects {
  constructor(
    private dataService: VirtualChannelService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.move(payload).pipe(
          map((response: IResponseSuccess) => fromActions.moveSuccess()),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.moveFailure(error));
          }),
        ),
      ),
    ),
  );
}
