import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ChannelContentAPIStoreEffects } from './effects';
import { channelContentAPIReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('channelContentAPI', channelContentAPIReducer),
    EffectsModule.forFeature([ChannelContentAPIStoreEffects]),
  ],
})
export class ChannelContentAPIStoreModule {}
