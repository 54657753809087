import { createAction, props } from '@ngrx/store';

export const checkRequest = createAction(
  '[Remote Check Accommodation Status] Check Request',
  props<{
    channelId: number;
    propertyId: number;
    accommodationChannelId: number;
  }>(),
);

export const checkSuccess = createAction(
  '[Remote Check Accommodation Status] Check Success',
);

export const checkFailure = createAction(
  '[Remote Check Accommodation Status] Check Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Remote Check Accommodation Status] Reset State',
);
